import * as React from 'react';
import * as Api from '../api/api';
import OfferRatesName from './OfferRatesName'
import { RatesCalculator } from '../utils/ratesUtils';
import Tooltip from './Tooltip';
import Table, { Column } from './Table';
import InputText from './InputText';
import Button from './Button';
import { Close } from '@material-ui/icons';
import * as Colors from "../styles/colors";
import Loader from './Loader';

type MultiPortOffersProps = MultiPortOffersOwnProps;

interface MultiPortOffersOwnProps {
    multiPort: Api.MultiPortInstanceModel;
    sizeTypes: { [id: number]: Api.SizeTypeModel };
    carriers: { [id: number]: Api.CarrierModel };
    currencies: { [id: number]: Api.CurrencyModel };
    isLoading: boolean;
    isDownloading: boolean;
    isClearing: boolean;
    newName: string;
    requestDowloadMultiPort: (multiPortInstanceId: number) => Promise<any>;
    requesUpdateMultiPortOffer: (multiPortOfferId: number) => Promise<any>;
    requesDeleteMultiPortOffer: (multiPortOfferId: number) => void;
    requestDeleteMultiPortInstance: () => Promise<any>;
    updateMultiPortName: (value: string) => void;
    requestCurrentMultiPort: () => void;
}

interface MultiPortOffersState {
}

const getColumns = (props: MultiPortOffersProps): Array<Column<Api.MultiPortOfferModel>> => {
    return [
        {
            header: 'Carrier',
            id: 'carrier',
            accessor: (d: Api.MultiPortOfferModel) => d.carrierId,
            cell: (d: any) => <div>
                {props.carriers[d].name}
            </div>
        },
        {
            header: 'Rates offer',
            id: 'ratesOffer',
            accessor: (d: Api.MultiPortOfferModel) => d,
            cell: (d: any) => {
                let multiPortOffer = d as Api.MultiPortOfferModel;
                if(!multiPortOffer.chargeSet){
                    return (<div></div>);
                }
                
                return (
                    <div>
                        <OfferRatesName
                            criteria={null}
                            focused={false}
                            chargeSet={multiPortOffer.chargeSet}
                            ratesOffer={multiPortOffer.ratesOffer}
                            subscriptionType={"Okargo"}
                            offerInformations={[]}
                        />
                    </div>);
            }
        },
        {
            header: 'All in',
            id: 'allIn',
            accessor: (d: Api.MultiPortOfferModel) => d,
            cell: (d: any) => {
                let multiPortOffer = d as Api.MultiPortOfferModel;
                let ratesCalculator = new RatesCalculator(props.currencies, props.sizeTypes, multiPortOffer.criteria);
                let currency = props.currencies[multiPortOffer.currencyId];
                return (
                    <div className="text-blue">
                        {Math.round(ratesCalculator.calculateAllIn(multiPortOffer.chargeSet).totalUsd / currency.value)}
                        <span> {currency.code}</span>
                    </div>);
            }
        },
        {
            header: 'Origin',
            id: 'origin',
            accessor: (d: Api.MultiPortOfferModel) => d.originPort,
            cell: (d: any) => {
                let location = d as Api.LocationModel;
                return (
                    <div>
                        {location?.name}
                    </div>);
            }
        },
        {
            header: 'Destination',
            id: 'destination',
            accessor: (d: Api.MultiPortOfferModel) => d.destinationPort,
            cell: (d: any) => {
                let location = d as Api.LocationModel;
                return (
                    <div>
                        {location?.name}
                    </div>);
            }
        },
        {
            header: 'Transit time',
            id: 'transitTime',
            accessor: (d: Api.MultiPortOfferModel) => d.routeConfig?.transitTime,
            cell: (d: any) => {
                return (
                    <div>
                        {d}
                    </div>);
            }
        },
        {
            header: 'TransShipment',
            id: 'transShipment',
            accessor: (d: Api.MultiPortOfferModel) => d.routeConfig?.transShipments,
            cell: (d: any) => {

                if (d != null) {

                    let locations = d as Array<Api.LocationModel>;
                    return locations.length === 0
                        ? <span className="text-green">Direct</span>
                        : <Tooltip
                            trigger={['hover']}
                            overlay={<span>{locations.map(lo => lo.name).join(", ")}</span>}>
                            <span>{locations.length}</span>
                        </Tooltip>;
                }
                else {
                    return (
                        <div>
                       
                        </div>);
                }

            }
        },
        {
            header: ' ',
            id: 'actions',
            accessor: (d: Api.MultiPortOfferModel) => d.multiPortOfferId,
            cell: (d: any) => {
                let multiPortOfferId = d as number;
                return <div>
                    <Close style={{ fontSize: 16, cursor: "pointer", color: Colors.baseColors.lightBlue }}
                        onClick={(e) => { props.requesDeleteMultiPortOffer(multiPortOfferId); e.preventDefault(); }} />
                </div>;
            }
        }
    ];
}
const emptyArray = [];

export default class MultiPortOffers extends React.Component<MultiPortOffersProps, MultiPortOffersState> {

    constructor(props) {
        super(props);
    }

    downloadMultiPort() {
        return this.props.requestDowloadMultiPort(this.props.multiPort.multiPortInstanceId)
            .then(() => {
                this.props.requestCurrentMultiPort();
            });
    }

    clearMultiPort(){
        return this.props.requestDeleteMultiPortInstance();
    }
    
    public render() {
        return (
            <div style={{ padding: "0px 20px 20px 20px" }}>
                <Loader isVisible={this.props.isClearing} top={100} size={60} message={"Clearing table..."} />
                <div style={{ fontSize: 20, marginBottom: 40 }}>
                    My multiport selection
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ marginRight: 10 }}>
                            <InputText
                                style={{ width: 400 }}
                                inputProps={{ style: { height: 45 } }}
                                value={this.props.newName}
                                onChange={(e) => this.props.updateMultiPortName(e.target.value)} />
                        </div>
                        <div>
                            <Button
                                style={{ height: 45 }}
                                disabled={this.props.isDownloading
                                    || !this.props.multiPort
                                    || this.props.multiPort.multiPortOffers.length === 0}
                                onClick={(e) => {
                                    this.downloadMultiPort();
                                }}>
                                Download Excel
                            </Button>
                        </div>
                        <div style={{ flex: 1 }}>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <Button
                                style={{ height: 45 }}
                                disabled={this.props.isDownloading
                                || !this.props.multiPort
                                || this.props.multiPort.multiPortOffers.length === 0}
                                onClick={(e) => {
                                    this.clearMultiPort();
                                }}>
                                Clear my list {this.props.isClearing}
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <Table
                        tableKey={"multiPortsOffer"}
                        showPagination={true}
                        rowsPerPage={8}
                        data={this.props.multiPort ? this.props.multiPort.multiPortOffers : emptyArray}
                        columns={getColumns(this.props)}
                        showPageSizeOptions={false}
                    />
                </div>
                
            </div>
        );
    }
}