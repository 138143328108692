import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as MultiPortHistoryStore from '../store/MultiPortHistory';
import * as MultiPortStore from '../store/MultiPort';
import { ApplicationState } from '../store';
import { RequestState } from '../models/models';
import { getText } from '../utils/langTexts';
import Loader from './Loader';
import DatePicker from './DatePicker';
import Table, { Column } from './Table';
import Button from './Button';

type HistoryMultiPortProps = HistoryMultiPortOwnProps
    & MultiPortHistoryStore.MultiPortState
    & typeof MultiPortHistoryStore.actionCreators
    & typeof MultiPortStore.actionCreators;

interface HistoryMultiPortOwnProps {
    downloadState: RequestState;
}

const getColumns = (props: HistoryMultiPortProps): Array<Column<Api.MultiPortInstanceModel>> => {
    return [
        {
            header: getText("HstMultiPortName"),
            id: 'name',
            accessor: (d: Api.MultiPortInstanceModel) => d.name,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstMultiPortOffersCount"),
            id: 'offers',
            accessor: (d: Api.MultiPortInstanceModel) => d.multiPortOffersCount,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("HstMultiPortDate"),
            id: 'creationDate',
            accessor: (d: Api.MultiPortInstanceModel) => d.creationDate,
            cell: (d: any) => <div>
                {Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: '',
            id: 'actions',
            accessor: (d: Api.MultiPortInstanceModel) => d.multiPortInstanceId,
            cell: (d: any) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ marginRight: 5 }}>
                        <span
                            className="action text-blue"
                            style={props.isLoading ? { color: "lightgray" } : {}}
                            onClick={(e) => {
                                if (!props.downloadState.isLoading) {
                                    props.requestDownloadMultiPort(new Date().getTime(), d);
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            {getText("HstMultiPortDownload")}
                        </span>
                    </div>
                    <div style={{}}>
                        <span
                            className="action text-blue"
                            style={props.isDeleteLoading ? { color: "lightgray" } : {}}
                            onClick={(e) => {
                                if (!props.isDeleteLoading) {
                                    props.requestDeleteMultiPortInstance(new Date().getTime(), d);
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            {getText("GenDeleteUpper")}
                        </span>
                    </div>
                </div>
            }
        },
    ];
}

const searchFieldStyle = { marginRight: 20 }
const searchContainerStyle = { marginBottom: 20 }
const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class HistoryMultiPort extends React.Component<HistoryMultiPortProps, {}> {

    onPropsUpdate(props: HistoryMultiPortProps) {
        if (new Date().getTime() - this.props.requestTime > 1000 * 60 * 10) {
            this.props.requestSearchMultiPorts(new Date().getTime());
        }
    }

    componentWillMount() {
        this.onPropsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: HistoryMultiPortProps) {
        this.onPropsUpdate(nextProps);
    }

    public render() {
        return (
            <div>
                <div style={searchContainerStyle}>
                    <form className="form-inline"
                        onSubmit={(e) => {
                            this.props.requestSearchMultiPorts(new Date().getTime());
                            e.preventDefault();
                        }}>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <label className="control-label">{getText("HstOfferMemoFilterFromDate")}</label>
                            <div style={{ display: "inline-block" }}>
                                <DatePicker
                                    value={this.props.searchMultiPorts.fromDate}
                                    onChange={(date) => this.props.updateHisoryFromDate(date)} />
                            </div>
                        </div>
                        <div className="form-group"
                            style={searchFieldStyle}>
                            <Button
                                type="submit"
                                style={{
                                    ...searchFieldStyle
                                }}>
                                {getText("HstMultiPortFilterSearch")}
                            </Button>
                        </div>
                    </form>
                </div>
                <h3>Multi ports</h3>
                <div>
                    <Table
                        tableKey={"historyMultiports"}
                        data={this.props.multiPorts}
                        columns={getColumns(this.props)}
                        rowsPerPage={8}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPagination={true}
                        showPageSizeOptions={false}
                        noDataText="No data, please use the filter above"
                        onBodyTrClick={(multiPort: Api.MultiPortInstanceModel) => {
                            if (multiPort) {
                                this.props.selectHistoryMultiPort(multiPort.multiPortInstanceId);
                            }
                        }}
                    />
                </div>
           {/*     <div style={{ marginTop: 10, marginBottom: 500 }}>*/}
                    {/*<CenteredContentTabs*/}
                    {/*    data={[*/}
                    {/*        {*/}
                    {/*            title: getText("HstMultiPortDownload"),*/}
                    {/*            tabContent: <div>*/}
                    {/*                <div style={{ textAlign: "center", padding: 10 }}>*/}
                    {/*                    {getText("HstMultiPortRecentDescription")}*/}
                    {/*                </div>*/}
                    {/*                <form*/}
                    {/*                    className="form-horizontal" style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}*/}
                    {/*                    onSubmit={(e) => {*/}
                    {/*                        this.props.requestDownloadRecentMultiPort(new Date().getTime());*/}
                    {/*                        e.preventDefault();*/}
                    {/*                    }}>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <label className="control-label col-md-5">{getText("HstMultiPortRecentMultiPort")}</label>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <Select*/}
                    {/*                                options={this.props.multiPorts.map(x => ({*/}
                    {/*                                    label: x.name,*/}
                    {/*                                    value: x.multiPortInstanceId*/}
                    {/*                                }))}*/}
                    {/*                                value={this.props.downloadRecentState.downloadRecentModel.multiPortInstanceId}*/}
                    {/*                                onChange={(value) => this.props.selectHistoryMultiPort(value)} />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <label className="control-label col-md-5">{getText("HstMultiPortRecentFromDate")}</label>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <DatePicker*/}
                    {/*                                value={this.props.downloadRecentState.downloadRecentModel.dateBegin}*/}
                    {/*                                onChange={(date) => this.props.updateDownloadRecentDateBegin(date)} />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <label className="control-label col-md-5">{getText("HstMultiPortRecentToDate")}</label>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <DatePicker*/}
                    {/*                                value={this.props.downloadRecentState.downloadRecentModel.dateEnd}*/}
                    {/*                                onChange={(date) => this.props.updateDownloadRecentDateEnd(date)} />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <div className="col-md-5">*/}
                    {/*                            {(this.props.downloadRecentState.isLoading)*/}
                    {/*                                && <img style={{ float: "right" }}*/}
                    {/*                                    src={"/images/loader.svg"}*/}
                    {/*                                    height={34} />}*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <Button*/}
                    {/*                                disabled={this.props.downloadRecentState.isLoading}*/}
                    {/*                                type="submit">*/}
                    {/*                                {getText("HstMultiPortDownloadRecent")}*/}
                    {/*                            </Button>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </form>*/}
                    {/*            </div>*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            title: getText("GenEdit"),*/}
                    {/*            tabContent: <div>*/}
                    {/*                <form*/}
                    {/*                    className="form-horizontal" style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto", marginTop: 25 }}*/}
                    {/*                    onSubmit={(e) => {*/}
                    {/*                        this.props.requestUpdateHistoryMultiPort(new Date().getTime());*/}
                    {/*                        e.preventDefault();*/}
                    {/*                    }}>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <label className="control-label col-md-5">{getText("HstMultiPortRecentMultiPort")}</label>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <Select*/}
                    {/*                                options={this.props.multiPorts.map(x => ({*/}
                    {/*                                    label: x.name,*/}
                    {/*                                    value: x.multiPortInstanceId*/}
                    {/*                                }))}*/}
                    {/*                                value={this.props.downloadRecentState.downloadRecentModel.multiPortInstanceId}*/}
                    {/*                                onChange={(value) => this.props.selectHistoryMultiPort(value)} />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <label className="control-label col-md-5">New name: </label>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <input type="text" className="form-control"*/}
                    {/*                                value={this.props.updateName}*/}
                    {/*                                onChange={(e) => this.props.updateHistoryMultiPortName(e.target.value)} />*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="form-group row">*/}
                    {/*                        <div className="col-md-5">*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-md-7">*/}
                    {/*                            <Button*/}
                    {/*                                disabled={this.props.updateMutiPortState.isLoading}*/}
                    {/*                                type="submit">*/}
                    {/*                                {getText("GenSaveChanges")}*/}
                    {/*                            </Button>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </form>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*    ]}*/}
                    {/*></CenteredContentTabs>*/}
        {/*        </div>*/}
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.multiPortHistory,
    downloadState: state.multiPort.downloadingOffersState
} as HistoryMultiPortProps),
    Object.assign(MultiPortStore.actionCreators, MultiPortHistoryStore.actionCreators))
    (HistoryMultiPort) as any